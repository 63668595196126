<template>
  <div>
    <b-sidebar
      id="sidebar-condition"
      ref="sidebarCondition"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-condition-header"
      right
      backdrop
      shadow
      no-header
      width="85vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter"> Conditions </b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div v-if="isLoading">
          <OtherLoading />
        </div>
        <div v-else>
          <div class="my-3">
            <b-form-radio-group v-model="isSelected" @change="setCondition">
              <b-row>
                <b-col>
                  <b-form-radio :value="2">Unconditional</b-form-radio>
                </b-col>
                <b-col><b-form-radio :value="1">Custom</b-form-radio> </b-col>
              </b-row>
            </b-form-radio-group>
          </div>
          <div v-if="isSelected === 1">
            <div v-for="(group, n) of form" :key="'group-' + n">
              <div class="card-condition-container">
                <div class="content-between">
                  <div class="card-group-title">
                    Group #{{ n + 1 }}
                    <div>
                      <InputSelectAutomation
                        v-bind:options="[
                          { value: 'and', text: 'And' },
                          { value: 'or', text: 'Or' },
                        ]"
                        :value="group.group_operation_type"
                        :noPleaseSelect="noPleaseSelect"
                        v-model="group.group_operation_type"
                        :isValidate="errorGroup[n]"
                        :indexGroup="n"
                        valueField="value"
                        textField="text"
                        @onDataChange="setGroupOperation"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option
                            value=""
                            v-if="group.group_operation_type == ''"
                            disabled
                            >-- Please Select Operation --</b-form-select-option
                          >
                          <b-form-select-option
                            :value="null"
                            v-if="group.group_operation_type == null"
                            disabled
                            >-- Please Select Operation --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </div>
                  </div>
                  <b-button
                    variant="light"
                    v-if="form.length > 1"
                    @click="removeGroup(n)"
                  >
                    <b-icon size="sm" icon="trash-fill" />
                  </b-button>
                </div>
                <b-row class="mt-3">
                  <b-col md="3">
                    <label>User Type <span class="text-error">*</span></label>
                  </b-col>
                  <b-col md="9">
                    <label
                      >Show Information <span class="text-error">*</span></label
                    >
                  </b-col>
                </b-row>

                <template v-if="!isLoading">
                  <div
                    v-for="(item, index) in group.condition"
                    :key="index"
                    class="mb-3"
                  >
                    <b-row
                      :set="
                        (optionType = getTypeOfOptions(item.condition_type_id))
                      "
                    >
                      <b-col md="3">
                        <InputSelectAutomation
                          v-bind:options="conditionGroup[n]"
                          :value="item.condition_type_id"
                          :v-model="item.condition_type_id"
                          :indexCondition="index"
                          :indexGroup="n"
                          @onDataChange="selectCondition"
                          valueField="id"
                          :v="
                            $v.form.$each.$iter[n].condition.$each.$iter[index]
                              .condition_type_id
                          "
                          :isValidate="
                            $v.form.$each.$iter[n].condition.$each.$iter[index]
                              .condition_type_id.$error
                          "
                          textField="name"
                        >
                          <template v-slot:option-first>
                            <b-form-select-option value="" disabled
                              >-- Please Select Type --</b-form-select-option
                            >
                          </template>
                        </InputSelectAutomation>
                      </b-col>

                      <b-col md="9">
                        <b-row
                          class="no-gutters"
                          v-if="item.condition_type_id === 10"
                        >
                          <b-col md="11" class="justify-content-start">
                            <UploadFileSendMessage
                              textFloat="File"
                              placeholder="Please Choose File"
                              format="excel"
                              name="file"
                              text="*Please upload only file .xlsx less than 10 MB"
                              isRequired
                              v-on:onFileChange="onFileChange"
                              :indexCondition="index"
                              :indexGroup="n"
                              v-on:delete="deleteFile"
                              :fileName="item.condition_value"
                              id="uploadfile_sendmessage"
                              :v="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value
                              "
                              :isValidate="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                              "
                            />
                          </b-col>
                          <b-col md="1" class="p-0">
                            <b-button
                              variant="light"
                              v-if="form[n].condition.length > 1"
                              @click="removeCondition(index, n)"
                            >
                              <b-icon size="sm" icon="trash-fill" />
                            </b-button>
                          </b-col>
                        </b-row>

                        <!--  -->

                        <b-row
                          v-else-if="
                            item.condition_type_id != '' &&
                            optionType == 'Dropdown'
                          "
                        >
                          <template v-if="item.condition_type_id == 34">
                            <b-col md="11">
                              <InputSelectAutomation
                                v-bind:options="[
                                  { id: '1', name: 'Yes' },
                                  { id: '0', name: 'No' },
                                ]"
                                :value="item.condition_value"
                                :v="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value
                                "
                                :isValidate="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                "
                                :noPleaseSelect="noPleaseSelect"
                                v-model="item.condition_value"
                                :indexCondition="index"
                                :indexGroup="n"
                                valueField="id"
                                @onDataChange="selectConditonValue"
                                textField="name"
                              >
                                <template v-slot:option-first>
                                  <b-form-select-option value="" disabled
                                    >-- Please Select Type
                                    --</b-form-select-option
                                  >
                                </template>
                              </InputSelectAutomation>
                            </b-col>
                          </template>
                          <template v-else-if="item.condition_type_id == 36">
                            <b-col md="5">
                              <InputSelectAutomation
                                v-bind:options="[
                                  {
                                    id: 3,
                                    name: 'Include',
                                  },

                                  {
                                    id: 7,
                                    name: 'Exclude',
                                  },
                                ]"
                                :value="item.operation_type_id"
                                :v="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].operation_type_id
                                "
                                :isValidate="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].operation_type_id.$error
                                "
                                :noPleaseSelect="false"
                                v-model="item.operation_type_id"
                                :indexCondition="index"
                                :indexGroup="n"
                                @onDataChange="selectOperation"
                                valueField="id"
                                textField="name"
                              >
                                <template v-slot:option-first>
                                  <b-form-select-option value="" disabled
                                    >-- Please Select Type
                                    --</b-form-select-option
                                  >
                                </template>
                              </InputSelectAutomation>
                            </b-col>
                            <b-col :md="'6'">
                              <InputDropdown
                                v-bind:options="
                                  getOptions(item.condition_type_id)
                                "
                                :value="item.condition_value"
                                :v="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value
                                "
                                :isValidate="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                "
                                :noPleaseSelect="noPleaseSelect"
                                v-model="item.condition_value"
                                :indexCondition="index"
                                :indexGroup="n"
                                @onDataChange="selectDropdownWithOperation"
                                valueField="id"
                                textField="name"
                                :name="'test' + '-' + index + '-' + n"
                              >
                              </InputDropdown>
                            </b-col>
                          </template>
                          <template v-else>
                            <b-col
                              :md="
                                item.condition_type_id == 17 ||
                                item.condition_type_id == 27 ||
                                item.condition_type_id == 37
                                  ? '6'
                                  : '11'
                              "
                            >
                              <SelectWithInfinite
                                v-if="
                                  item.condition_type_id == 17 ||
                                  item.condition_type_id == 27 ||
                                  item.condition_type_id == 37
                                "
                                :value="item.condition_value"
                                :options="getOptions(item.condition_type_id)"
                                valueField="id"
                                placeholder="Select product."
                                textField="name"
                                @input="
                                  (value) =>
                                    handleSelectProduct(n, index, value)
                                "
                              />

                              <InputDropdown
                                v-else
                                v-bind:options="
                                  getOptions(item.condition_type_id)
                                "
                                :value="item.condition_value"
                                :v="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value
                                "
                                :isValidate="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                "
                                :noPleaseSelect="noPleaseSelect"
                                v-model="item.condition_value"
                                :indexCondition="index"
                                :indexGroup="n"
                                @onDataChange="selectConditonValue"
                                valueField="id"
                                textField="name"
                                :name="'test' + '-' + index + '-' + n"
                              >
                              </InputDropdown>
                            </b-col>
                            <b-col
                              v-if="
                                item.condition_type_id == 17 ||
                                item.condition_type_id == 27
                              "
                              md="5"
                            >
                              <UploadFileV2
                                textFloat=""
                                placeholder="Please Choose File"
                                format="excel"
                                name="file"
                                text=""
                                :data="item"
                                v-on:onFileChange="onImportFileChange"
                                :fileName="fileUpload.name"
                                :showFileName="false"
                                :indexCondition="index"
                                :indexGroup="n"
                                v-model="fileUpload.excel_file"
                                id="uploadfile"
                            /></b-col>
                          </template>
                          <b-col md="1" class="p-0">
                            <b-button
                              variant="light"
                              v-if="form[n].condition.length > 1"
                              @click="removeCondition(index, n)"
                            >
                              <b-icon size="sm" icon="trash-fill" />
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row
                          v-else-if="
                            item.condition_type_id != '' &&
                            (optionType == 'int' || optionType == 'Double')
                          "
                        >
                          <b-col md="5">
                            <InputSelectAutomation
                              v-bind:options="operatorList"
                              :value="item.operation_type_id"
                              :v="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].operation_type_id
                              "
                              :isValidate="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].operation_type_id.$error
                              "
                              :noPleaseSelect="noPleaseSelect"
                              v-model="item.operation_type_id"
                              :indexCondition="index"
                              :indexGroup="n"
                              @onDataChange="selectOperation"
                              valueField="id"
                              textField="name"
                            >
                              <template v-slot:option-first>
                                <b-form-select-option value="" disabled
                                  >-- Please Select Type
                                  --</b-form-select-option
                                >
                              </template>
                            </InputSelectAutomation>
                          </b-col>
                          <template v-if="item.operation_type_id == 6">
                            <b-col md="3">
                              <b-form-input
                                v-model="item.condition_value"
                                placeholder="value"
                                type="number"
                                :class="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                    ? 'border-danger'
                                    : ''
                                "
                              ></b-form-input>
                              <div
                                v-if="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                "
                                class="text-error"
                              >
                                Please input.
                              </div>
                            </b-col>
                            <b-col md="3">
                              <b-form-input
                                v-model="item.between_value"
                                placeholder="value"
                                type="number"
                                :class="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                    ? 'border-danger'
                                    : ''
                                "
                              ></b-form-input>
                              <div
                                v-if="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                "
                                class="text-error"
                              >
                                Please input.
                              </div>
                            </b-col>
                          </template>
                          <template v-else>
                            <b-col md="6">
                              <b-form-input
                                v-model="item.condition_value"
                                placeholder="value"
                                type="number"
                                :class="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                    ? 'border-danger'
                                    : ''
                                "
                              ></b-form-input>
                              <div
                                v-if="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                "
                                class="text-error"
                              >
                                Please input.
                              </div>
                            </b-col>
                          </template>
                          <b-col md="1" class="p-0">
                            <b-button
                              variant="light"
                              v-if="form[n].condition.length > 1"
                              @click="removeCondition(index, n)"
                            >
                              <b-icon size="sm" icon="trash-fill" />
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row
                          v-else-if="
                            item.condition_type_id != '' &&
                            (optionType == 'Datetime' ||
                              optionType == 'Birthday')
                          "
                        >
                          <b-col md="3">
                            <InputSelectAutomation
                              v-bind:options="
                                item.condition_type_id == 32 ||
                                item.condition_type_id == 33
                                  ? dateOperatorList2
                                  : optionType == 'Birthday'
                                  ? birthDayOperator
                                  : dateOperatorList
                              "
                              :value="item.operation_type_id"
                              :v="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].operation_type_id
                              "
                              :isValidate="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].operation_type_id.$error
                              "
                              :noPleaseSelect="noPleaseSelect"
                              v-model="item.operation_type_id"
                              :indexCondition="index"
                              :indexGroup="n"
                              @onDataChange="selectOperation"
                              valueField="id"
                              textField="name"
                            >
                              <template v-slot:option-first>
                                <b-form-select-option value="" disabled
                                  >-- Please Select Type
                                  --</b-form-select-option
                                >
                              </template>
                            </InputSelectAutomation>
                          </b-col>

                          <template>
                            <b-col md="3">
                              <InputSelect
                                :name="'date-type-' + n + index"
                                isRequired
                                v-model="item.date_type"
                                :v="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].date_type
                                "
                                :isValidate="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].date_type.$error
                                "
                                customClass="mb-0"
                                v-bind:options="
                                  optionType == 'Birthday'
                                    ? birthDayType
                                    : dateTypeOptions
                                "
                                valueField="id"
                                textField="name"
                                :disabled="item.operation_type_id == 6"
                              >
                                <template v-slot:option-first>
                                  <b-form-select-option value="" disabled>
                                    -- Select Date Type --
                                  </b-form-select-option>
                                </template>
                              </InputSelect>
                            </b-col>
                          </template>
                          <template v-if="item.date_type == 3">
                            <template v-if="optionType == 'Birthday'">
                              <b-col md="5">
                                <b-row>
                                  <b-col md="12">
                                    <InputDropdown
                                      v-bind:options="birthDayOptions"
                                      :value="item.condition_value"
                                      :v="
                                        $v.form.$each.$iter[n].condition.$each
                                          .$iter[index].condition_value
                                      "
                                      :isValidate="
                                        $v.form.$each.$iter[n].condition.$each
                                          .$iter[index].condition_value.$error
                                      "
                                      :noPleaseSelect="noPleaseSelect"
                                      v-model="item.condition_value"
                                      :indexCondition="index"
                                      :indexGroup="n"
                                      @onDataChange="selectBirthDateCustom"
                                      valueField="id"
                                      textField="name"
                                      :name="'test' + '-' + index + '-' + n"
                                    >
                                    </InputDropdown>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </template>
                            <template v-else>
                              <b-col md="5">
                                <b-row>
                                  <b-col md="6">
                                    <div
                                      class="input-container"
                                      :class="
                                        $v.form.$each.$iter[n].condition.$each
                                          .$iter[index].condition_value.$error
                                          ? 'border-danger'
                                          : ''
                                      "
                                    >
                                      <datetime
                                        type="datetime"
                                        class="date-picker mb-0"
                                        :input-style="styleDatetime"
                                        v-model="item.condition_value"
                                        placeholder="DD/MM/YYYY (HH:MM)"
                                        format="dd/MM/yyyy (HH:mm)"
                                        value-zone="Asia/Bangkok"
                                        ref="transferDateStart"
                                      >
                                      </datetime>
                                      <div
                                        :class="'icon-primary text-right'"
                                        @click="
                                          $refs.transferDateStart.isOpen = true
                                        "
                                      >
                                        <font-awesome-icon
                                          icon="calendar-alt"
                                          :class="'pointer color-primary'"
                                          color="#B41BB4"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      v-if="
                                        $v.form.$each.$iter[n].condition.$each
                                          .$iter[index].condition_value.$error
                                      "
                                      class="text-error"
                                    >
                                      Please input.
                                    </div>
                                  </b-col>
                                  <b-col md="6">
                                    <template
                                      v-if="item.operation_type_id == 6"
                                    >
                                      <div
                                        class="input-container"
                                        :class="
                                          $v.form.$each.$iter[n].condition.$each
                                            .$iter[index].condition_value.$error
                                            ? ' border-danger'
                                            : ''
                                        "
                                      >
                                        <datetime
                                          type="datetime"
                                          class="date-picker mb-0"
                                          :input-style="styleDatetime"
                                          v-model="item.between_value"
                                          placeholder="DD/MM/YYYY (HH:MM)"
                                          format="dd/MM/yyyy (HH:mm)"
                                          value-zone="Asia/Bangkok"
                                          ref="transferDateStart"
                                        >
                                        </datetime>
                                        <div
                                          :class="'icon-primary text-right'"
                                          @click="
                                            $refs.transferDateStart.isOpen = true
                                          "
                                        >
                                          <font-awesome-icon
                                            icon="calendar-alt"
                                            :class="'pointer color-primary'"
                                            color="#B41BB4"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        v-if="
                                          $v.form.$each.$iter[n].condition.$each
                                            .$iter[index].between_value.$error
                                        "
                                        class="text-error"
                                      >
                                        Please input.
                                      </div>
                                    </template>
                                  </b-col>
                                </b-row>
                              </b-col>
                            </template>
                          </template>
                          <template v-if="item.date_type != 3">
                            <b-col md="5">
                              <b-row
                                ><b-col md="6">
                                  <InputSelect
                                    :name="'date-type-' + n + index"
                                    isRequired
                                    customClass="mb-0"
                                    v-model="item.operation"
                                    v-bind:options="[
                                      { id: 1, name: 'Plus' },
                                      { id: 2, name: 'Minus' },
                                    ]"
                                    valueField="id"
                                    textField="name"
                                  >
                                    <template v-slot:option-first>
                                      <b-form-select-option value="0" disabled>
                                        -- additional --
                                      </b-form-select-option>
                                    </template>
                                  </InputSelect>
                                </b-col>
                                <b-col md="6">
                                  <InputText
                                    textFloat=""
                                    placeholder="0"
                                    type="number"
                                    class="mb-0"
                                    v-model="item.operation_value"
                                    :name="'operation_value-' + n + index"
                                  />
                                </b-col>
                              </b-row>
                            </b-col>
                          </template>
                          <b-col class="p-0">
                            <b-button
                              variant="light"
                              v-if="form[n].condition.length > 1"
                              @click="removeCondition(index, n)"
                            >
                              <b-icon size="sm" icon="trash-fill" />
                            </b-button>
                          </b-col>
                        </b-row>

                        <b-row v-else>
                          <b-col md="11"> </b-col>
                          <b-col md="1" class="p-0">
                            <b-button
                              variant="light"
                              v-if="form[n].condition.length > 1"
                              @click="removeCondition(index, n)"
                            >
                              <b-icon size="sm" icon="trash-fill" />
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="mt-3">
                    <button
                      @click.prevent="addRuleCondition(n)"
                      class="btn-add"
                      :disabled="group.disabledCreate"
                    >
                      <font-awesome-icon icon="plus-square" class="mr-2" />
                      <span class="add-new">Create a New Conditions</span>
                    </button>
                  </div>
                </template>
              </div>
              <b-row
                v-if="form.length > 1 && n + 1 != form.length"
                class="mb-2"
              >
                <b-col cols="4">
                  <InputSelectAutomation
                    v-bind:options="conditonOperator"
                    :value="group.operation_type"
                    :noPleaseSelect="noPleaseSelect"
                    v-model="group.operation_type"
                    :isValidate="errorGroup[n]"
                    @onDataChange="setOperation"
                    :indexGroup="n"
                    valueField="value"
                    textField="text"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option
                        value=""
                        v-if="group.operation_type == ''"
                        disabled
                        >-- Please Select Type --</b-form-select-option
                      >
                      <b-form-select-option
                        :value="null"
                        v-if="group.operation_type == null"
                        disabled
                        >-- Please Select Type --</b-form-select-option
                      >
                    </template>
                  </InputSelectAutomation>
                </b-col>

                <b-col cols="12" class="text-error" v-if="errorGroup[n]"
                  >Please select value.</b-col
                >
              </b-row>
            </div>
          </div>
          <div class="mt-3" v-if="isSelected === 1">
            <button @click.prevent="addGroupConditions" class="btn-add">
              <font-awesome-icon icon="plus-square" class="mr-2" />
              <span class="add-new">Create a New Group</span>
            </button>
          </div>
          <slot name="template-condition"></slot>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
              :disabled="isLoading"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              :disabled="isLoading"
              class="text-body rounded-pill btn-main"
              @click.prevent="submitCondition"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import InputSelectAutomation from "@/components/inputs/InputSelectAutomation";
import InputDropdown from "@/components/inputs/InputAutomationDropdown";
import DisplayCampaignField from "@/components/automation/component/DisplayCampaignField";
import UploadFileSendMessage from "@/components/automation/segmentation/UploadFileSendMessage";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, url } from "vuelidate/lib/validators";
export default {
  name: "SidebarConditionGroup",
  components: {
    InputSelectAutomation,
    UploadFileSendMessage,
    OtherLoading,
    DisplayCampaignField,
    InputDropdown,
    UploadFileV2,
  },
  props: {
    privilegeList: {
      required: true,
      // type: Array,
    },
    serviceList: {
      required: false,
    },
    branchList: {
      required: false,
    },
    productList: {
      required: false,
    },
    campaignList: {
      required: false,
    },
    stampList: {
      required: false,
      // type: Array,
    },
    conditionList: {
      required: false,
      type: Array,
    },
    memberLevel: {
      required: false,
      type: Array,
    },
    gender: {
      required: false,
      type: Array,
    },
    operatorList: {
      required: false,
      type: Array,
    },
    listCondition: {
      required: false,
      type: [Array, undefined],
    },
    v: {
      required: true,
      type: Object,
    },
    selected: {
      required: true,
      type: Number,
    },
    formMain: {
      required: false,
      type: Object,
    },
    campaignFieldConditions: {
      required: false,
      type: Array,
    },
    hasImportUser: {
      required: false,
      default: false,
    },
    customerTag: {
      required: false,
    },
    segmentList: {
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      isShowSidebar: false,
      condition_type_id: 0,
      noPleaseSelect: true,
      isSelected: this.selected,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      filename: "",
      fileBase64: "",
      isLoading: false,
      form: [],
      conditonOperator: [
        { text: "And", value: "and" },
        { text: "Or", value: "or" },
        { text: "And Not", value: "not" },
      ],
      conditionGroup: [],
      errorGroup: [],
      fileUpload: {
        name: "",
        excel_file: "",
      },
      dateTypeOptions: [
        {
          id: 1,
          name: "Today",
        },
        {
          id: 2,
          name: "This month",
        },
        {
          id: 3,
          name: "Custom Date",
        },
      ],
      birthDayType: [
        {
          id: 2,
          name: "This month",
        },
        {
          id: 3,
          name: "Custom Date",
        },
      ],
      dateOperatorList: [
        {
          id: 1,
          name: "After",
        },
        {
          id: 2,
          name: "Before",
        },
        {
          id: 3,
          name: "IS",
        },
        {
          id: 4,
          name: "After or Equal",
        },
        {
          id: 5,
          name: "Before or Equal",
        },
        {
          id: 6,
          name: "Between",
        },
      ],
      dateOperatorList2: [
        {
          id: 1,
          name: "After",
        },
        {
          id: 2,
          name: "Before",
        },
        {
          id: 3,
          name: "IS",
        },
        {
          id: 4,
          name: "After or Equal",
        },
        {
          id: 5,
          name: "Before or Equal",
        },
      ],
      birthDayOperator: [
        {
          id: 1,
          name: "After",
        },
        {
          id: 2,
          name: "Before",
        },
        {
          id: 3,
          name: "IS",
        },
        {
          id: 4,
          name: "After or Equal",
        },
        {
          id: 5,
          name: "Before or Equal",
        },
      ],
      birthDayOptions: [],
    };
  },
  validations: {
    form: {
      $each: {
        condition: {
          $each: {
            condition_type_id: {
              required: requiredIf(function (item) {
                return !item.condition_type_id;
              }),
            },
            operation_type_id: {
              required: requiredIf(function (item) {
                return !item.operation_type_id;
              }),
            },
            condition_value: {
              required: requiredIf(function (item) {
                return item.date_type == 1 || item.date_type == 2
                  ? false
                  : item.condition_type_id && !item.condition_value;
              }),
            },
            date_type: {
              required: requiredIf(function (item, value) {
                return this.getTypeOfOptions(item.condition_type_id) ==
                  "Datetime" ||
                  this.getTypeOfOptions(item.condition_type_id) == "Birthday"
                  ? true
                  : false;
              }),
            },
            between_value: {
              required: requiredIf(function (item) {
                return item.date_type == 3 && item.operation_type_id == 6
                  ? item.condition_type_id && !item.between_value
                  : false;
              }),
            },
          },
        },
      },
    },
  },
  watch: {},
  methods: {
    getTypeOfOptions(id) {
      if (id)
        return this.conditionList.find((el) => el.id == id)
          ? this.conditionList.find((el) => el.id == id).type
          : "";
    },
    getOptions(id) {
      console.log(id);
      switch (id) {
        case 1:
          return this.memberLevel;
        case 2:
          return this.gender;
        case 7:
          return this.privilegeList;
        case 11:
          return this.stampList;
        case 18:
          return this.campaignList;
        case 17:
          return this.productList;
        case 27:
          return this.serviceList;
        case 16:
          return this.branchList;
        case 23:
          return this.branchList;
        case 25:
          return this.branchList;
        case 34:
          return [
            { id: "yes", name: "Yes" },
            { id: "no", name: "No" },
          ];
        case 36:
          return this.customerTag;
        case 37:
          return this.productList;
        case 38:
          return this.branchList;
        case 45:
          return this.memberLevel;
        case 46:
          return this.segmentList;
        default:
          break;
      }
    },
    show() {
      // this.conditionGroup = [];
      this.birthDayOptions = [];

      this.isSelected = this.selected;
      if (this.isSelected == 0) {
        this.isSelected = 2;
      }
      for (let i = 0; i < 12; i++) {
        this.birthDayOptions.push({
          id: i + 1,
          name: this.$moment().month(i).format("MMMM"),
        });
      }

      if (this.isSelected === 1) {
        this.$v.$reset();
        let tempObj = [...this.listCondition];

        if (tempObj[0].condition.length == 0) {
          tempObj[0].condition.push({
            condition_type_id: "",
            operation_type_id: "",
            condition_value: "",
            condition_type_name: "",
            group_operation_type: "and",
            between_value: "",
            date_type: "",
            operation: 1,
            operation_value: 0,
          });
        }

        this.form = JSON.parse(JSON.stringify(tempObj));

        this.disabledOptions();
      }
      this.isShowSidebar = true;
    },
    hide() {
      this.conditionGroup = [];
      this.isShowSidebar = false;
    },
    disabledOptions() {
      var condition = this.form;
      var groupCondtion = [];
      let disableImport = false;
      this.isLoading = true;
      for (const o of condition) {
        var con = JSON.parse(
          JSON.stringify(
            this.conditionList.map((el) => {
              return { ...el, disabled: false };
            })
          )
        );
        if (o.condition.find((el) => el.condition_type_id == 10)) {
          disableImport = true;
        }
        for (const a of con) {
          if (a.id == 10) {
            if (disableImport) a.disabled = true;
          } else if (o.condition.find((el) => el.condition_type_id == a.id)) {
            a.disabled = true;
          } else {
            a.disabled = false;
          }
        }
        groupCondtion.push(con);
      }
      this.isLoading = false;
      this.conditionGroup = JSON.parse(JSON.stringify(groupCondtion));
    },
    addRuleCondition(index) {
      this.form[index].condition.push({
        condition_type_id: "",
        operation_type_id: "",
        condition_value: "",
        condition_type_name: "",
        between_value: "",
        group_operation_type: this.form[index].group_operation_type,
        date_type: "",
        operation: 1,
        operation_value: 0,
      });
      this.disabledOptions();
    },
    async addGroupConditions() {
      await this.form.push({
        group: this.form.length + 1,
        import_condition: "",
        operation_type: "",
        disabledCreate: false,
        group_operation_type: "and",
        condition: [
          {
            condition_type_id: "",
            operation_type_id: "",
            condition_value: "",
            condition_type_name: "",
            group_operation_type: "and",
            between_value: "",
            date_type: "",
            operation: 1,
            operation_value: 0,
          },
        ],
      });

      this.disabledOptions();
    },

    handleSelectProduct(group, index, value) {
      this.form[group].condition[index].condition_value = value;
      this.form[group].condition[index].operation_type_id = 3;
    },
    selectConditonValue(...value) {
      this.form[value[3]].condition[value[1]].condition_value = value[0];
      this.form[value[3]].condition[value[1]].operation_type_id = 3;
    },
    selectDropdownWithOperation(...value) {
      this.form[value[3]].condition[value[1]].condition_value = value[0];
    },
    selectBirthDateCustom(...value) {
      this.form[value[3]].condition[value[1]].condition_value = value[0];
      // this.form[value[3]].condition[value[1]].operation_type_id = 3;
    },
    removeCondition(index, group) {
      if (this.form[group].condition.length == 1 && this.form.length > 1) {
        this.form[group - 1].operation_type = "";
        return this.form.splice(group, 1);
      }
      this.form[group].condition.splice(index, 1);
      this.disabledOptions();
    },
    removeGroup(group) {
      this.form.splice(group, 1);
      var i = 1;
      for (const f of this.form) {
        f.group = i;
        i++;
      }
      if (group == 0) {
        this.form[group].operation_type = "";
      } else {
        this.form[group - 1].operation_type = "";
      }
      return this.disabledOptions();
    },

    async selectCondition(...value) {
      this.form[value[3]].condition[value[1]].condition_type_id = value[0];
      this.form[value[3]].condition[value[1]].condition_value = "";
      this.form[value[3]].condition[value[1]].between_value = "";
      this.form[value[3]].condition[value[1]].date_type = "";
      this.form[value[3]].condition[value[1]].operation_type_id = "";
      this.form[value[3]].condition[value[1]].operation = 1;
      this.form[value[3]].condition[value[1]].condition_type_name = value[2];
      this.form[value[3]].disabledCreate = false;

      // if selected TypeId 10 -> remove other conditions
      let filterTypeId10 = await this.form[value[3]].condition.filter(
        (el) => el.condition_type_id === 10
      );
      if (filterTypeId10.length > 0) {
        this.form[value[3]].disabledCreate = true;
        this.form[value[3]].condition = filterTypeId10;
      }
      this.$v.form.$reset();
      this.disabledOptions();
    },
    selectOperation(...value) {
      this.form[value[3]].condition[value[1]].operation_type_id = value[0];
      if (value[0] == 6) this.form[value[3]].condition[value[1]].date_type = 3;
      else this.form[value[3]].condition[value[1]].date_type = "";
      this.form[value[3]].condition[value[1]].operation = 1;
      this.form[value[3]].condition[value[1]].condition_value = "";
      this.form[value[3]].condition[value[1]].between_value = "";
      this.form[value[3]].condition[value[1]].operation_type_name = value[2];
      this.form[value[3]].condition[value[1]].operation_value = 0;
    },
    async submitCondition() {
      if (this.isSelected) {
        if (this.isSelected === 2) {
          this.$emit("settingCondition", this.isSelected);
          this.$emit("setForm", []);
          this.hide();
        } else {
          this.$v.form.$touch();
          var error = [];
          for (let n = 0; n < this.form.length - 1; n++) {
            if (this.form[n].operation_type != "") {
              error[n] = false;
            } else error[n] = true;
          }
          this.errorGroup = error;

          if (this.$v.form.$error || !error.every((el) => el == false)) {
            return;
          }

          this.$emit("setForm", this.form);

          var obj = this.form.filter(
            (el) =>
              el.condition_type_id != 12 &&
              el.condition_type_id != 13 &&
              el.condition_type_id != 14
          );

          let objTemp = obj;
          objTemp = objTemp.filter((el) => el.condition_value);

          this.$emit("settingCondition", this.isSelected, objTemp);
          this.hide();
        }
      } else {
        this.$swal("Please set conditions", {
          icon: "warning",
        });
      }
    },
    setCondition() {
      if (this.isSelected === 1) {
        if (this.form.length == 0) this.addGroupConditions();
        else if (this.form[0].condition.length < 1) this.addRuleCondition(0);
      }
      // if (this.form.length != this.conditionGroup.length)
      this.disabledOptions();
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    onFileChange(...file) {
      this.filename = file[0].name;
      this.isDisable = false;
      if (this.hasImportUser) {
        this.setFormFile(file);
        return this.$emit("importUser", file[0]);
      }
      this.filename = file[0].name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        this.form[file[2]].import_condition = reader.result.substring(
          str.length,
          reader.result.length
        );
        this.checkFormFile(file);
      };
    },
    async checkFormFile(file) {
      this.isLoading = true;
      let body = {
        excel_file: this.form[file[2]].import_condition,
      };
      await this.$store.dispatch("checkFileExcel", body);
      const data = this.$store.state.automation.stateCheckFileExcel;
      if (data.result === 1) {
        if (data.detail > 0) {
          this.setFormFile(file);
        } else {
          this.isLoading = false;
          this.form[file[2]].import_condition = "";
          this.filename = null;
          const msg = "Please check the information throughly";
          this.$swal(msg, {
            icon: "warning",
          });
        }
      } else {
        this.isLoading = false;
        this.form[file[2]].import_condition = "";
        this.filename = null;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    setFormFile(file) {
      this.isLoading = false;

      this.form[file[2]].condition[file[1]].condition_value = file[0].name;
      this.form[file[2]].condition[file[1]].operation_type_id = 3;
    },
    deleteFile(indexGroup, indexCondition) {
      this.filename = null;
      this.form[indexGroup].import_condition = "";
      this.form[indexGroup].condition[indexCondition].condition_value = "";
      this.isDisable = true;
    },
    setOperation(...value) {
      this.form[value[3]].operation_type = value[0];
      var error = [];
      for (let n = 0; n < this.form.length - 1; n++) {
        if (this.form[n].operation_type != "") {
          error[n] = false;
        } else error[n] = true;
      }

      this.errorGroup = error;
    },
    setGroupOperation(...value) {
      this.form[value[3]].group_operation_type = value[0];
      let temp = [];
      for (const iterator of this.form[value[3]].condition) {
        iterator.group_operation_type = value[0];
        temp.push(iterator);
      }
      this.form[value[3]].condition = JSON.parse(JSON.stringify(temp));
    },
    async onImportFileChange(file, groupIndex, index, id) {
      this.$bus.$emit("showLoading");
      this.fileUpload.name = file.name;

      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      let value = null;
      reader.readAsDataURL(file);
      reader.onload = async () => {
        value = reader.result.substring(str.length, reader.result.length);
        this.fileUpload.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
        let body = {
          excel_file: value,
        };
        let url = "";
        if (id == 17) {
          url = `/MarketingAutomation/importproductcondition`;
        } else {
          url = `/MarketingAutomation/importservicecondition`;
        }
        const res = await this.axios.post(url, body);

        if (this.form[groupIndex].condition[index].condition_value != "") {
          this.form[groupIndex].condition[index].condition_value = [
            ...this.form[groupIndex].condition[index].condition_value,
            ...res.data.detail.map((el) => String(el)),
          ];
        } else
          this.form[groupIndex].condition[index].condition_value =
            res.data.detail.map((el) => String(el));

        this.form[groupIndex].condition[index].condition_value = [
          ...new Set(this.form[groupIndex].condition[index].condition_value),
        ];
        this.form[groupIndex].condition[index].operation_type_id = 3;
        this.fileUpload.name = null;
        this.fileUpload.excel_file = "";
        this.$bus.$emit("hideLoading");
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  color: var(--font-color);
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.btn-add:disabled {
  opacity: 0.5;
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-danger {
  border-color: red;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
::v-deep .date-picker .vdatetime-input {
  margin-bottom: 0;
  padding: 2px 10px;
}
.card-condition-container {
  background: var(--theme-secondary-color);
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  color: var(--font-color);
}
.card-group-title {
  color: var(--primary-color);
  font-weight: bold;
  display: flex;
  align-items: center;
  column-gap: 5px;
}
</style>
